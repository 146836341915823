import React, { useState } from 'react';
import Call from '../../assets/images/call.png';
import Mail from '../../assets/images/mail.png';
import Header from '../../components/Header/Header';
import PrivacyPolicyLink from '../../components/PrivacyPolicyLink/PrivacyPolicyLink';
import useTranslate from '../../hooks/useTranslate';
import { useSelector } from 'react-redux';
import { mkUnsubLink } from '../../constants/unsublinks';
import useGetPageConfig from '../../hooks/useGetPageConfig';
import TnCLink from '../../components/TnCLink/TnCLink';
import FAQLink from '../../components/FAQLink/FAQLink';
import RefundPolicyLink from '../../components/RefundPolicyLink/RefundPolicyLink';
import makeApiRequest from '../../utils/makeApiRequest';
import storage from '../../utils/storage';
import { Modal } from '../../components/Modal/Modal';
import { AiFillCloseCircle as CloseIcon } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import { isCreditCardPortal } from '../../constants/cc';
import { config } from '../../constants/domainConfig';
// import { useAlert } from '../../components/AlertContainer/AlertContainer';
// import { emailRegex } from '../../constants/emailRegex';

export default function HelpCentre() {
    const _localStorage = storage('local');
    const navigate = useNavigate();
    const { token, msisdn } = useSelector((state) => state.access);
    const uid = token || new URLSearchParams(window.location.search).get('uid') || new URLSearchParams(window.location.search).get('token');
    const t = useTranslate();
    const { subdomain, lang, product } = useGetPageConfig();
    const [showModal, setShowModal] = useState(false);
    const [msg, setMsg] = useState('');
    const email = msisdn || _localStorage.get('msisdn');

    const onUnsubscribeClick = async () => {
        if (isCreditCardPortal()) {
            try {
                const response = await makeApiRequest({
                    url: 'https://cc.tallymans.com/api/v1/portal/unsubscribe',
                    method: 'POST',
                    body: { email: email },
                });
                if (response.state === 'success') {
                    setShowModal(true);
                    setMsg(response.message);
                    setTimeout(() => navigate('/login'), 2000);
                    return;
                } else if (response.state === 'failure') {
                    setShowModal(true);
                    setMsg(response.message);
                    return;
                }
            } catch (err) {
                setShowModal(true);
                setMsg('Something went wrong!');
            }
        } else {
            return window.open(mkUnsubLink(subdomain, { token: uid, lang }));
        }
    };
    const onClose = () => {
        setShowModal(false);
    };
    const mailToHref =
        product === 'fun-box'
            ? 'help@fun-box.net'
            : product === 'entertainu'
            ? 'help@entertainu.tv'
            : product === 'flixvid'
            ? 'help@flixvid.net'
            : product === 'sparkstream'
            ? 'help@sparkstream.tv'
            : product === 'mixstreamflix'
            ? 'help@mixstreamflix.com'
            : 'help@moviotv.online';
    return (
        <>
            <Header forceHideHamburger />
            <div className="help-centre-page">
                <div className="help-centre-title">
                    <h1>{t('help_centre')}</h1>
                </div>
                <div className="help-centre-about-us">
                    {t(
                        product === 'fun-box'
                            ? 'about_service_funbox'
                            : product === 'entertainu'
                            ? 'about_service_entertainu'
                            : product === 'flixvid'
                            ? 'about_service_flixvid'
                            : product === 'sparkstream'
                            ? 'about_service_sparkstream'
                            : product === 'mixstreamflix'
                            ? 'about_service_mixstreamflix'
                            : 'about_service'
                    )}
                </div>
                {uid && (
                    <div className="help-centre-unsub">
                        <div className="help-centre-unsub-title">{t('how_to_cancel', { br: () => <br /> })}</div>
                        <button className="help-centre-unsub-button" onClick={() => onUnsubscribeClick()}>
                            {t('cancel_membership')}
                        </button>
                    </div>
                )}
                <div className="help-centre-main">
                    <div className="help-centre-main-title">{t('call_or_email_us')}</div>
                    <div className="help-centre-main-container">
                        <div className="help-centre-main-item">
                            <img src={Mail} alt="" />
                            <a href={`mailto:${mailToHref}`}>
                                {product === 'fun-box' ? (
                                    <span>help@funbox.net</span>
                                ) : product === 'entertainu' ? (
                                    <span>help@entertainu.tv </span>
                                ) : product === 'flixvid' ? (
                                    <span>help@flixvid.net</span>
                                ) : product === 'mixstreamflix' ? (
                                    <span>help@mixstreamflix.com</span>
                                ) : product === 'sparkstream' ? (
                                    <span>help@sparkstream.tv</span>
                                ) : (
                                    <span>help@moviotv.online</span>
                                )}
                            </a>
                        </div>
                        <div className="help-centre-main-item">
                            <img src={Call} alt="" />
                            <a href="tel:0805985498">
                                <span>{config.product === 'entertainu' ? '+35777788378' : config.product === 'moviotv' ? '+35777788486' : '+35777788373'}</span>
                            </a>
                        </div>
                        {/* <div className="help-centre-main-item">
                            <img src={Call} alt="" />
                            <a href="tel:900751051">
                                <span>900-751-051</span> (Spain)
                            </a>
                        </div> */}
                    </div>
                </div>
                <div className="help-centre-links">
                    <FAQLink />
                    <RefundPolicyLink />
                    <PrivacyPolicyLink />
                    <TnCLink />
                </div>
            </div>
            {showModal && (
                <Modal onClose={onClose}>
                    <div className="unsubscribe-modal">
                        <div className="unsub-close-icon" onClick={() => onClose()}>
                            <CloseIcon />
                        </div>
                        <h2>{msg}</h2>
                    </div>
                </Modal>
            )}
        </>
    );
}
